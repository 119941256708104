

@font-face {
    font-family: 'panton_narrowregular';
    src: url('../fonts/panton_narrow_regular-webfont.woff2') format('woff2'),
         url('../fonts/panton_narrow_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pantonregular';
    src: url('../fonts/panton_regular-webfont.woff2') format('woff2'),
         url('../fonts/panton_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'panton_semiboldregular';
    src: url('../fonts/panton_semibold-webfont.woff2') format('woff2'),
         url('../fonts/panton_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}