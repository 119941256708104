/* menu cards */
body.menuG, body.menuB, body.menuC, body.menuW, body.menuM {
    background-color: $c-white;
}

.menucard__header {
    padding-top: 35px;
    margin-bottom: 80px;
    @media screen and (max-width: 990px) {
        margin-bottom: 60px;
    }
    @media screen and (max-width: 650px) {
        padding-top: 20px;
        margin-bottom: 40px;
        text-align: center;
    }

}

.menucard .container {
    max-width: 750px !important;
}

.menucard__list h1 {
    margin-top: 0px;
    text-align: center;
    color: $c-gold;
    font-family: $panton_narrowregular;
    font-size: 5.3125rem;
    margin-bottom: 80px;
    @media screen and (max-width: 990px) {
        margin-bottom: 60px;
        font-size: 4.3125rem;
    }
    @media screen and (max-width: 650px) {
        margin-bottom: 20px;
        font-size: 3.3125rem;
    }
}

.menucard__list h1 span {
    text-align: center;
    display: block;
    font-size: 3.3125rem;
    @media screen and (max-width: 990px) {
        font-size: 2.3125rem;
    }
    @media screen and (max-width: 650px) {
        font-size: 1.3125rem;
    }
}

.menucard__group {
    text-align: center;
    margin-bottom: 60px;
    font-size: 1.5625rem;
    @media screen and (max-width: 990px) {
        margin-bottom: 40px;        
        font-size: 1.25rem;
    }
    @media screen and (max-width: 650px) {
        margin-bottom: 40px;
        font-size: 1rem;
    }
}

.menucard__group a {
    color: $c-gold;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    &:hover {
        color: $c-brown-light;
    }
}

.menucard__group h2 {
    margin-top: 0px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    display: inline-block;
    width: auto;
    position: relative;
    font-size: 2.8125rem;
    @media screen and (max-width: 650px) {
        font-size: 1.5rem;     
    }
    &::before {
        position: absolute;
        content: "";
        width: calc(100% + 100px);
        height: 1px;
        background-color: $c-gold;
        left: -50px;
        bottom: -0px;
        @media screen and (max-width: 650px) {
            width: calc(100% + 50px);    
            left: -25px;
        }
    }
    
}
.menucard__grid__basic .menucard_grid_price{
    margin-bottom: 35px;
    @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
}
.menucard__grid__basic  .menucard_grid_item {
    margin-bottom: 5px;
}

.menucard__grid__tab {
    margin-bottom: 35px;
    @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
}

.menucard__grid__items {
    display: flex;    
    margin-bottom: 15px;
}

.menucard__grid__items .menucard_grid_item {
    flex: 1;
    text-align: left;
}
.menucard__grid__items .menucard_grid_price {
    margin-left: 20px;
    text-align: right;
}