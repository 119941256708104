/* GALERY */
.gallery__top {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  justify-content: center;
  color: $c-white;;
  padding-top: 200px;
  padding-bottom: 0px;
  background-image: url(./img/back_gallery.jpg);
  @media screen and (max-width: 650px) {
    padding-top: 300px;
    padding-bottom: 0px;
  }
}

.gallery__top h1 {
  margin-top: 0;
  margin-bottom: 65px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 25px;
    
  }
  
}
.gallery_wrap {
  display: flex;
  gap: 30px;
  
  @media screen and (max-width: 768px) {
    gap: 15px;
  }
  @media screen and (max-width: 650px) {
    gap: 0px;
    display: block;
  }
}
.gallery_item {
  position: relative;
  display: block;
  flex: 1 1 0px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}
.gallery_item img {
  width: 100%;
}
.gallery_item figure {
  width: 100%;
  padding: 0;
  margin: 0;
}
.gallery_item figcaption {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 15px;
  text-align: center;
  background-color: rgba($c-gold,0.6);
  color: $c-white;
}
.gallery_article {
  padding-top: 30px;
}