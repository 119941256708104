/* HOME */
.hp__top {
    
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 80vh;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.hp__top.winter {
  background-image: url(./img/hp_top_winter.jpg);
}
.hp__top.spring {
  background-image: url(./img/hp_top_spring_2023.jpg);
}

.hp__top  .container {
  display: flex;
  align-items: flex-end;
}

.hp__top__head {
  margin-top: 0;
  margin-bottom: 95px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  padding-top: 260px;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 45px;
    
  }
  @media screen and (max-width: 450px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 25px;
  }
}
.hp__info {
  background-color: $c-white;
}
.hp__info h1 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 5.3125rem;
  line-height: 5rem;
  padding-top: 80px;
  padding-bottom: 50px;
  color: $c-gold;
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.4375rem;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.hp__info article {
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url(./img/trees_back.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $c-white;
  font-size: 1.875rem;
  line-height: 2.8125rem;
  text-align: center;
  @media screen and (max-width: 990px) {
    font-size: 1.5rem;
    line-height: 2.6rem;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.25rem;
    line-height: 2.5rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.hp__leftBox {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 70vh;
  margin-top: 205px;
  font-size: 1rem;
  text-align: center;
  @media screen and (max-width: 650px) {
    min-height: 30vh;
  }
  @media screen and (max-width: 450px) {
    margin-top: 155px;
  }
}
.hp__leftBox h2 {
  font-size: 2.5rem;
  line-height: normal;
  margin: 20px 0px 0px 0px;
  padding: 0;
  text-transform: uppercase;
  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
    margin: 10px 0px 0px 0px;
  }
}
.hp__leftBox article {
  background-color: $c-white;
  padding: 75px 100px;
  text-align: center;
  width: 85%;
  max-width: 700px;
  margin-left: 20px;
  position: relative;
  transform: translateY(-50%);
  @media screen and (max-width: 650px) {
    padding: 35px 50px;
  }
  @media screen and (max-width: 450px) {
    padding: 15px 20px;
  }
}

.hp__rightBox {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 70vh;
  margin-top: 205px;
  font-size: 1rem;
  text-align: center;
  @media screen and (max-width: 650px) {
    min-height: 30vh;
  }
  @media screen and (max-width: 450px) {
    margin-top: 155px;
  }
}
.hp__rightBox h2 {
  font-size: 2.5rem;
  line-height: normal;
  margin: 20px 0px 0px 0px;
  padding: 0;
  text-transform: uppercase;
  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
    margin: 10px 0px 0px 0px;
  }
}
.hp__rightBox article {
  background-color: $c-white;
  padding: 75px 100px;
  text-align: center;
  width: 85%;
  max-width: 700px;
  margin-right: 20px;
  margin-left: auto;
  position: relative;
  transform: translateY(-50%);
  @media screen and (max-width: 650px) {
    padding: 35px 50px;
  }
  @media screen and (max-width: 450px) {
    padding: 15px 20px;
  }
}


.hp__boxRooms {
  background-image: url(./img/hp_rooms_2023.jpg);
}
.hp__boxRest {
  background-image: url(./img/hp_restaurace_2023.jpg);
}
.hp__boxWell {
  background-image: url(./img/hp_wellnes_2023.jpg);
}

.hp__alert {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  text-align: center;
  background-color: transparent !important;
  background-image: none !important;
  color: $c-gold !important;
  font-size: 1.5rem !important;
  line-height: 2.1rem !important;
  @media screen and (max-width: 650px) {
    font-size: 1rem !important;
  line-height: 1.3rem !important;
  }
}

.gFamily {
  background-color: #FFFFFF;
  padding-top: 40px;
  padding-bottom: 20px;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 35px;
  }
}

.gFamily .container {
 display: grid;
 gap: 20px;
 grid-template-columns: 1fr;
  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  @media screen and (min-width: 990px) {
    gap: 70px;
  }
}

.gFamily_txt {
  font-size: 1rem;
  @media screen and (min-width: 650px) {
    font-size: 1.3rem;
   }
}

.gFamily_txt_logos {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
   @media screen and (min-width: 650px) {
    margin-top: 65px;
    flex-wrap: nowrap;
   }
   @media screen and (min-width: 650px) and (max-width: 1206px) {
    flex-wrap: wrap;
    margin-top: 25px;
   }
}

.gFamily_txt_logos a{
  display: block;
  width: calc(50% - 10px);
   @media screen and (min-width: 450px) {
    width: auto;
   }
   @media screen and (min-width: 650px) and (max-width: 1206px) {
    width: calc(50% - 10px);
   }
}

.gFamily_txt_logos img {
  display: block;
  margin: 0 auto;
}

.gFamily_carousel {
  overflow: hidden;
  padding-bottom: 55px;
  @media screen and (min-width: 650px) {
    padding-bottom: 75px;
   }
}
.homepageCar {
  width: 100%;
}

.gFamily_carousel_img {
  display: block;
  width: 100%;
  height: auto;
}

.gFamily_btn {
  display: block;
 width: 48px;
 height: 48px;
 position: absolute;
 bottom: -55px;
 left: 60px;
 background-image: url(./img/carArrow.svg);
 background-size: 48px 48px;
 background-repeat: no-repeat;
 background-size: center center;
 cursor: pointer;
 border: none;
 transform: rotate(0deg);
 @include  trans();
 &:hover {
   transform: rotate(-30deg);
 }
 @media screen and (min-width: 650px) {
  bottom: -75px;
 }
}

.gFamily_btnNext {
  left: 0px;
  transform: rotate(180deg);
  &:hover {
    transform: rotate(210deg);
  }
}

.logo_shoda {
  display: block;
  width: 51px;
  height: auto;
}


