/* rooms */
.rooms__top {
  background-image: url(./img/back_rooms2.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  justify-content: center;
  color: $c-white;;
  padding-top: 200px;
  padding-bottom: 60px;
  @media screen and (max-width: 650px) {
    padding-top: 300px;
    padding-bottom: 40px;
  }
}

.rooms__top h1 {
  margin-top: 0;
  margin-bottom: 95px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 45px;
    
  }
  
}

.rooms__txt {
  margin-top: -60px;
}

.rooms__txt p {
  margin: 0;
  padding: 60px 55px;
  background-color: $c-white;
  text-align: center;
  color: $c-grey;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  margin-left: 60px;
  margin-right: 60px;
  @media screen and (max-width: 990px) {
    padding: 40px 35px;
    font-size: 1.3625rem;
    line-height: 1.575rem;
    margin-left: 40px;
    margin-right: 40px;
  }
  @media screen and (max-width: 650px) {
    padding: 20px 25px;
    font-size: 1.1rem;
    line-height: 1.25rem;
    margin-left: 10px;
    margin-right: 10px;
    
  }
}

.rooms__filters {
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: 769px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }  
}

.rooms__filters ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 900px) {
    justify-content: center;
  }  
}

.rooms__filters li {
  display: block;
  position: relative;
  &::before {
      content: "";
      position: absolute;
      right: 0px;
      top: 11px;
      width: 1px;
      height: 15px;
      background-color: $c-gold;
  }
  &:last-child{
    &::before {
      display: none;
    }
    
  }
}

.rooms__filters li a {
  display: block;
  padding: 7px 12px;
  color: $c-grey;
  font-size: 1.25rem;
  line-height: 1.875rem;
  @media screen and (max-width: 650px) {
    
    font-size: 1.25rem;
   line-height: 1.875rem;
   padding: 4px 8px;
  }
  &:hover {
    text-decoration: underline;
  }
}
.room__slider {
  position: relative;
}
.room__slider__img {
  width: 100%;
  object-position: center center;
  object-fit: cover;
  @media screen and (max-width: 1240px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 150%;
  }
  @media screen and (max-width: 650px) {
    width: 150%;
  }
  @media screen and (max-width: 450px) {
    width: 150%;
  }
  
}
.room__left, .room__right {
  margin-bottom: 70px;
  @media screen and (max-width: 650px) {
    margin-bottom: 40px;
  }
}
.room__left .container__noPadd, .room__right .container__noPadd {
  overflow: hidden;
}

.room_info {
  position: absolute;
  z-index: 60;
  left: 0px;
  top: 0px;
  height: 100%;
  max-width: 50%;
  display: inline-block;
  
  padding: 60px 75px 80px 75px;
  background-color: rgba($c-grey,0.8);
  @include trans();
  @media only screen and (max-width: 1024px) {
    max-width: 60%;
    padding: 40px 65px 70px 65px;
  }
  @media only screen and (max-width: 990px) {
    max-width: 70%;
      padding: 30px 35px 40px 35px;
  }
  @media only screen and (max-width: 768px) {
      
    padding: 30px 35px 40px 35px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    position: relative;
    padding: 15px 25px 20px 15px;
    margin-top: -50px;
    }
    
    
    
}
.room__right {
  .room_info {
    left: inherit;
    right: 0px;
    padding: 60px 75px 80px 75px;
    @media only screen and (max-width: 1024px) {
      padding: 40px 65px 70px 65px;
    }
    @media only screen and (max-width: 990px) {
        padding: 30px 35px 40px 35px;
    }
    @media only screen and (max-width: 768px) {
        
      padding: 30px 35px 40px 35px;
    }
    @media only screen and (max-width: 650px) {
      padding: 15px 10px 10px 40px;
      }
  }
    
}
.room_info h2 {
  font-size: 2.5125rem;
  line-height: 3.525rem;
  color: rgba($c-white,1);
  margin-bottom: 20px;
  margin-top: 0px;
  @media only screen and (max-width: 650px) {
    margin-bottom: 10px;
    font-size: 1.9875rem;
    line-height: 2.2375rem;
  } 
}
.room_info p {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: rgba($c-white,1);
  margin-bottom: 20px;
  padding-right: 20px;
  @media only screen and (max-width: 650px) {
    
    font-size: 0.9375rem;
  line-height: 1.25rem;
  } 
}

.room_info.hiddenRoom {
  @include trans();
  left: calc(-49.5% + 40px);
  @media only screen and (max-width: 1024px) {
    left: calc(-60% + 40px);
  }
  @media only screen and (max-width: 990px) {
    left: calc(-70% + 40px);
  }
  @media only screen and (max-width: 650px) {
    left: inherit;
    right: inherit;
    }
   
}

.room__right {
  .room_info.hiddenRoom {
    @include trans();
    left: inherit;
    right: calc(-49.5% + 40px);
    @media only screen and (max-width: 1024px) {
      left: inherit;
      right: calc(-60% + 40px);
    }
    @media only screen and (max-width: 990px) {
      left: inherit;
      right: calc(-70% + 40px);
    }
    @media only screen and (max-width: 650px) {
      left: inherit;
      right: inherit;
      }
     
  }
}

.room_info.showRoom {
  @include trans();
  
}
.room__icons {
  margin-bottom: 20px;
  @media only screen and (max-width: 990px) {
    margin-bottom: 10px;
  } 
}
.room__icons img {
  margin-right: 20px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0px;
  }
  @media only screen and (max-width: 990px) {
    transform: scale(0.7);
    margin-right: 5px;
    margin-bottom: 2px;
  } 
}
.room_buttonRez {
  display: inline-block;
  padding: 23px 25px 19px 25px;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  background-color: rgba($c-white,1);
  border: 1px solid $c-white;
  color: rgba($c-grey,1);
  @include trans();
  &:hover {
    background-color: rgba($c-white,0);
    color: rgba($c-white,1);
    @include trans();
  }
  @media only screen and (max-width: 650px) {
    padding: 13px 15px 8px 15px;
  } 
}
.room_closer {
    width: 17px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('./img/arrowleft.svg');
    background-size: 17px 30px;
    transform: rotate(0deg);
    @include trans();
    @media only screen and (max-width: 650px) {
      display: none;
    } 
}
.room_info.hiddenRoom {
  .room_closer {
    @include trans();
      transform: rotate(180deg);
  }  
}
.room__right {
  .room_closer {
    right: inherit;
    left: 10px;
    transform: rotate(180deg);
  }  
  .room_info.hiddenRoom {
    .room_closer {
      @include trans();
        transform: rotate(0deg);
    }  
  }
} 

.rooms .tns-outer {
  position: relative;
}
.rooms .tns-controls {
  position: absolute;
  
  right: 0px;
  top: 50%;
  width: calc(52% - 48px);
  transform: translateY(-50%);
  z-index: 20;
  height: 80px;
  @include trans();
  @media only screen and (max-width: 990px) {
    width: calc(100% - 48px);
    left: 24px !important;
  } 
}

.room__close .tns-controls, .room__close .tns-nav {
  @include trans();
  width: calc(100% - 48px) !important;
}

.room__right {
  .tns-controls {
    right: inherit;
    left: 0px;
  } 
}
.rooms .tns-controls button {
  position: absolute;
  background-color: transparent;

  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./img/arrowleftCarousel.svg');
  background-size: 46px 80px;
  width: 46px;
  height: 80px;
  margin: 0;  
  top: 0px;
  display: block;
  &:first-child {
    left: 24px;
  }
  &:last-child {
    right: 24px;
    transform: rotate(180DEG);
  }
}

.rooms .tns-nav {
  position: absolute;
  
  right: 0px;
  bottom: 20px;
  width: calc(52% - 48px);
  z-index: 20;
  height: 25px;
  @include trans();
  text-align: center;
  @media only screen and (max-width: 990px) {
    width: calc(100% - 48px);
    left: 24px !important;
  } 
  @media only screen and (max-width: 650px) {
    bottom: 60px;
  } 
}
.room__right {
  .tns-nav {
    right: inherit;
    left: 0px;
  } 
}
.rooms .tns-nav button {
  @include trans();
  background-color: rgba($c-white,0);
  width: 20px;
  height: 20px;
  border: 2px solid rgba($c-white,1);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4); 
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4);
  display: inline-block;
  border-radius: 50%;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
}
.rooms .tns-nav-active {
  @include trans();
  background-color: rgba($c-white,1) !important;
}





