/* HEADERS */

$menuToggle: 650px;

.header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  padding-top: 5 * $p-basic;
  padding-bottom: 3 * $p-basic;
  @include trans();
  @media screen and (max-width: $menuToggle) {
    padding-top: 0;
  } 
}
.header-xmass {
  padding-top: 7 * $p-basic;
  @media screen and (max-width: $menuToggle) {
    padding-top: 0;
  } 
}

.header__wrapp {
  width: calc(100% - 90px);
   @media  screen and (max-width: $menuToggle) {
       position: absolute;
       left: 0px;
       top: 100px;
       width: 100%;
  }
}
.header__logo {
 width: 100%;
  max-width: 450px;
  padding-right: 3 * $p-basic;
  @media  screen and (max-width: $menuToggle) {
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90px;
    position: absolute;
    left: 10px;
    top: -60px;
}
}

.header__logo__txt {
  position: relative;
  z-index: 100;
  color: $c-white;
  
}
.header__logo__txt a.map {
  color: $c-white;
  text-decoration: underline;
  @include trans(0.3s, color);
  &:hover {
    color: $c-pink;
    @include trans(0.3s, color);
  }
}


.header__logo img {
  width: 100%;
  max-width: 170px;

  display: block;
  margin-bottom: 2 * $p-basic;
  @media  screen and (max-width: $menuToggle) {
    margin-left: auto;
    margin-right: auto;
     max-width: 80px;
  }
}


.header__logo .link-pink {
  color: $c-pink;
  font-family: $pantonregular;
  a {
    color: $c-pink;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.header__menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}


.header__tort {
  display: block;
  width: 380px;
  position: absolute;
  left: 50%;
  margin-left: 25px;
  top: 0px;
  font-size: 1.263rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-family: $pantonregular;
  text-align: center;
  color: $c-pink;
  text-decoration: none;
  z-index: 101;
  &:hover {
      text-decoration: underline;


  }
  @media  screen and (max-width: 800px) {
   width: 290px;
   z-index: 20;
  }
  @media  screen and (max-width: 805px) {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0%;
    top: 30px;
    margin-top: 2 * $p-basic;
      }
}
.header__tort__hand {
          animation: handAnim 1.3s ease-in-out 0s infinite forwards;
      }
@keyframes handAnim {
  0% {
      transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }

}

.header__tort img {
  width: 50px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}
.header__toogle__holder {
  width: 260px;
  height: 70px;
  position: absolute;
  top: -15px;
  right: 0px;
  display: flex;
  z-index: 201;
  flex-wrap: wrap;
  @media screen and (max-width: $menuToggle) {
    width: 200px;
    height: 40px;
    top: -5px;  
  }
}

.header__toggle {
  
  width: 80px;
  height: 70px;
  background-color: $c-grey;
  background-image: url(./img/hamburger.svg);
  background-size: 30px 26px;
  background-repeat: no-repeat ;
  background-position: center center;
  border: none;
   z-index: 201;
   cursor: pointer;
   @media screen and (max-width: $menuToggle) {
    height: 50px;
    width: 50px;
  }
}

.header__menu__wrap {
    background-color: $c-grey-dark;
    background-image: url('./img/menu_back.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    top: -100vh;

    left: 0px;
    position: fixed;
    z-index: 200;
    @include trans(0.5s, top);
    padding-top: 17 * $p-basic;

     @media  screen and (max-width: $menuToggle) {
        padding-top: 10 * $p-basic;
     }
     @media  screen and (max-height: 380px) {
      padding-top: 8 * $p-basic;
    }
}
.header__menu__wrap ul {
  margin: 0px 0px 0px 0px;
  list-style: none;
  width: 100%;
}

.header__menu__wrap ul li {
  margin: 0px;
  display: block;

  text-align: center;
}
.header__menu__wrap ul li a {
  padding: 5px 30px;
  display: block;
  text-align: center;
  color: $c-white;
  text-transform: uppercase;
  text-decoration: none;
  @include trans(0.3s, color);

  font-family: $pantonregular;
  font-size: 1.95rem;
  @media  screen and (max-width: 651px) {
       font-size: 1.5rem;
       padding: 5px 20px;
      }
   @media  screen and (max-height: 640px) {
       font-size: 1.3rem;
       padding: 5px 15px;
      }
      @media  screen and (max-height: 380px) {
        font-size: 1rem;
        padding: 2px 15px;
      }   
  &:hover {
    color: $c-brown-light;

    @include trans(0.3s, color);
  }

}

.header__menu__ss__mob {
  margin-top: 40px !important;
}

.header__menu__ss__mob a {
  width: auto;
  display: inline-block !important;
   @include trans();
  transform: rotate(0deg);
    &:hover {

      @include trans();
      transform: rotate(-20deg);
    }
  &.social img {
    width: 40px;
    height: 40px;
    opacity: 0.8;
  }
}

.showMenu {
  .header__toggle {
    background-image: url(./img/hamburger-x.svg);

  }
  .header__menu__wrap {
      @include trans(0.5s, top);
      top:0px;


  }

}


.header__nav__social {
  @media  screen and (max-width: 700px) {
       display: none;
      }
  width: 110px;
  position: absolute;
  right: 55px;
  top: -7px;
  text-align: center;
  z-index: 1000;
  .social {
    display: inline-block;
     width: 40px;
    height: 40px;
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    z-index: 100;
    @include trans();
    transform: rotate(0deg);
    &:hover {
      @include trans();
      transform: rotate(-20deg);
    }
  }
}
.menu {
  padding-bottom: 0px;
}
.menu__wrap {
  background-color: $c-white;
  padding-bottom: 100px;
  padding-top: 35px;
  @media screen and (max-width: 650px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.menu__box {
  float: left;
  width: calc(100% - 376px);
  padding-right: 6 * $p-basic;
  max-width: 940px;
  @media screen and (max-width: 990px) {
    width: calc(100% - 250px);
      padding-right: 3 * $p-basic;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 35px;
  }
}

.header__logo__logo {
  position: relative;
  z-index: 3000;
  @media  screen and (max-width: $menuToggle) {
    z-index: 1;
    
  }
}

.menu__box h1 {
  text-transform: uppercase;
  font-family: $pantonregular;
  font-weight: normal;
  font-size: 2.89rem;
  padding: 0px 0px 20px 0px;
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 1.89rem;
  }
}

.menu__box__toggleWeek {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.menu__box__toggleWeek li {
  margin: 0;
  padding: 0px 0px 20px 32px;
  display: block;
  font-family: $panton_narrowregular;
  font-weight: normal;
  font-size: 1.56rem;
  background-size: 21px 21px;
  background-repeat: no-repeat;
  background-position: left top 8px;
  background-image: url(./img/radioOff.png);
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
    background-position: left top 6px;
  }
}
.menu__box__toggleWeek li.active {
  font-family: $pantonregular;
  background-image: url(./img/radioOn.png);
}

.menu__box__toggleWeek li a {
  color: $c-grey;
  text-decoration: none;
  @include trans(0.3s, color);
  &:hover {
    color: $c-brown-light;
    @include trans(0.3s, color);
  }
}
.menu__aside {
  float: right;
  width: 376px;
  padding-left: 12px;
  padding-right: 12px;
  @media screen and (max-width: 990px) {
      width: 250px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;;
  }
}

.menu__aside {
  display: flex;
  flex-wrap: wrap;
  .hpBox__item {
    width: 100%;
    color: $c-black;
    margin-right: 0px;
    &::before, &::after {
      border-color: $c-black;
    }
  }
  .hpBox__item h2, .hpBox__item__quote {
    &::before {
           background-color: $c-black;
     }
  }
}

.menu__mainToggle {
  padding: 0px 0px 20px 0px;
}

.menu__mainToggle ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  width: auto;
}
.menu__mainToggle li {
  float: left;
  margin-right: 30px;
  position: relative;
  &::before {
    content: "|";
    position: absolute;
    right: -30px;
    top: 0px;
    width: 30px;
    height: 100%;
    color: $c-grey;
    z-index: 1;
    text-align: center;
  }
  &:last-child {
    &::before {
      display: none;
      width: 0px;
    }
  }
}
.menu__mainToggle a {
  font-family: $panton_semiboldregular;
  color: $c-grey;
}
.menu__mainToggle li.active a {
  color: $c-brown-light;
}

.group__header {
  border-bottom: 1px solid $c-grey;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.group__title {
  margin-bottom: 0px;
  font-size: 1.875rem;
  text-transform: uppercase;
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
  }
}
.menu__items {
  padding: 0;
  width: 100%;
  margin: 0px 0px 40px 0px;
}
.menu__items td {
  font-family: $panton_narrowregular;
  color: $c-grey;
  font-size: 1.4rem;
  vertical-align: top;
  @media screen and (max-width: 650px) {
    font-size: 1rem;
  }
}
.menu__items__quantinty {
  width: 70px;
  padding: 5px 10px 5px 0px;
  @media screen and (max-width: 650px) {
    width: 50px;
  }
}
.menu__items__name {
  padding: 5px 10px 5px 0px;
}
.menu__items__price {
  width: 100px;
  text-align: right;
  padding: 5px 10px 5px 0px;
  @media screen and (max-width: 650px) {
    width: 60px;
  }
}

.menu__print {
  position: relative;
}
.menu__print  button{
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  background-color: transparent;
}

.menu__print__icon {
  width: 50px;
  height: 50px;

}
.header__nav__lang {
    position: relative;
    text-align: right;
    z-index: 180;
    width: 100%;
    //background-color: red;

    @media  screen and (max-width: $menuToggle) {
        position: relative;
       
    }  


    a {
       @include trans(0.3s, color);
       color: $c-white;
        font-family: $pantonregular;
        font-size: 1.3rem;
        &:hover {
          color: $c-brown-light;

          @include trans(0.3s, color);
        }
    }
}

.header__nav__lang ul {
  margin: 0;
  padding: 10px 3px 3px 3px;
  list-style-type: none;
}
.header__nav__lang li {
 text-align: center;
  display: inline-block;
  position: relative;
  margin-right: 26px;
  @media screen and (max-width: $menuToggle) {
    margin-right: 18px;
  }
  &::before {
    content: "";
    position: absolute;
    background-color: $c-white;
    width: 2px;
    height: 16px;
    right: -16px;
    top: 4px;
    @media screen and (max-width: $menuToggle) {
      right: -13px;
      top: 6px;
    }
  }
  
  &:last-child {
    margin-right: 10px;
    &::before {
      display: none;
    }
  }
}
.header__nav__lang.showMenu ul {
  display: block;
}
.header__nav__lang.showMenu button {
  &::before {
    @include trans();
    transform: rotate(-90deg);
  }
}
.header__nav__lang button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1.25rem;
    color: $c-grey;
    position: relative;
    width: 70px;
    height: 70px;
    padding-top: 9px;
    padding-left: 20px;
    
    &::before {
      @include trans();
      content: "";
      position: absolute;
      width: 7px;
      height: 12px;
      left: 14px;
      top: 29px;
      background-image: url('./img/arrowleftGrey.svg');
      background-repeat: no-repeat;
      background-size: 7px 12px;
      transform: rotate(0deg);
    }
}

.header__nav__rezervation {
  display: flex;
  flex: 1;
  background-color: $c-brown-light;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: $c-grey;
  font-size: 1.5625rem;
  line-height: normal;
  @include trans();
  &:hover {
      @include trans();
      background-color: $c-gold;
      color: $c-white;
  }
}
.header__nav__rezervation span {
  position: relative;
  top: 1px;
}

.header__logo__logo_scroller {
  display: none !important;
}
.topMenu__scrolled {
  @include trans();
  padding-top: 14px;
  padding-bottom: 0px;
  background: rgb(255,255,255);
  background: white;
  height: 130px;
  @media  screen and (max-width: $menuToggle) {
    padding-top: 0px;
    height: 105px;
  } 

  .header__logo img {
    max-width: 120px;
  }

  .header__toogle__holder {
    top: -15px;
    @media  screen and (max-width: $menuToggle) {
      top: 0px;
    } 
  }

  .header__nav__lang a {
    color: #3C3C3B;
  }

  .header__nav__lang li::before {
    background-color: #3C3C3B;
  }
  .header__logo__logo {
    display: none;
  }
  .header__logo__logo_scroller {
    display: block !important;
    top: 15px;
    @media  screen and (max-width: $menuToggle) {
      top: -5px;
    } 
  }
}


.topMenu__topHidden {
  top: -200px !important;
  @include trans();
  @media screen and (max-width: $menuToggle) {
    top: -120px !important;
  }
}
