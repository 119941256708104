/* HOME */

.res__top {
    background-image: url(./img/trees_back2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    align-items: stretch;
    justify-content: center;
    color: $c-white;;
    padding-top: 200px;
    padding-bottom: 60px;
    @media screen and (max-width: 650px) {
      padding-top: 300px;
      padding-bottom: 20px;
    }
}

.res__top h1 {
  display: block;
  margin-top: 0;
  margin-bottom: 40px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 20px;
    
  }
}