html,
body {
    font-size: 15px;
    padding: 0;
    margin: 0;
    font-family: $panton_narrowregular;
    color: $c-grey-dark;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    background-color: $c-grey-back;

}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


.hidden {
  display: none;
}


strong {
    font-family: $panton_semiboldregular;
    font-weight: 600;
}
.basic__top {
  //background-image: url(./img/hp_back.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 255px;
  position: relative;
  @media  screen and (max-width: 805px) {
       padding-top: 405px;
      }
      @media  screen and (max-width: 551px) {
       padding-top: 360px;
      }
      @media  screen and (max-width: 451px) {
       padding-top: 365px;
      }
}

/* KONTAIERY */

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    @media only screen and (min-width: 640px) {
        padding-right: 16px;
        padding-left: 16px;
    }
    @media only screen and (min-width: 768px) {
        padding-right: 24px;
        padding-left: 24px;
    }
    @media only screen and (min-width: 990px) {
        padding-right: 36px;
        padding-left: 36px;
    }
    @media only screen and (min-width: 1024px) {
        padding-right: 48px;
        padding-left: 48px;
    }
    @media only screen and (min-width: 1376px) {
        max-width: 1376px;
        padding-right: 48px;
        padding-left: 48px;
    }
}

.container__noPadd {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (min-width: 1366px) {
        max-width: 1280px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.container__basicPad {
    padding-top: 3 * $p-basic;
    padding-bottom: 5 * $p-basic;
    @media only screen and (max-width: 768px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 4 * $p-basic;
    }
    @media only screen and (max-width: 640px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}

.link-pink {
  color: $c-pink;
  font-family: $pantonregular;
  a {
    color: $c-pink;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* CLEARFIX */

.cf:before,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.mb-6 {
    margin-bottom: 6 * $p-basic;
}

.mb-4 {
    margin-bottom: 4 * $p-basic;
}

.mb-3 {
    margin-bottom: 3 * $p-basic;
}

.mb-2 {
    margin-bottom: 2 * $p-basic;
}

.mt-6 {
    margin-top: 6 * $p-basic;
}

.mt-4 {
    margin-top: 4 * $p-basic;
}

.mt-3 {
    margin-top: 3 * $p-basic;
}

.mt-2 {
    margin-top: 2 * $p-basic;
}

.ta-center {
    text-align: center;
}



/* MODAL */

.modal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($c-grey, 0.40);
    display: flex;
    align-items: center;
    align-content: center;
}

.modal__body {
    width: 100%;
    max-width: 700px;
    background-color: rgba($c-gold, 0.75);
    margin: (3 * $p-basic) auto (3 * $p-basic) auto;
    padding: 15px 30px 30px 30px;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 560px) {
        padding: 12px 10px 10px 10px;
    }
    position: relative;
}

.modal__close {
    width: 100%;
    height: 24px;
    margin-bottom: 20px;
    background-image: url(./img/icon_close.svg);
    background-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 31px 24px;
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    @include trans();
    &:hover {
        @include trans();
        opacity: 0.5;
    }
}

.modal__form {
    
    margin: 0;
    padding: 0;
}

.modal__hidden {
    display: none !important;
}

.modal__content h3 {

    text-transform: uppercase;
    margin-bottom: 2 * $p-basic;
}

.modal__content h2 {
    text-transform: uppercase;
    margin-bottom: 4.5 * $p-basic;

}

.modal__form__group {
    margin-bottom: 10px;
}
.modal__form__group__flex {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.modal__q {
    height: 56px;
    width: auto;
}
.modal__btn {
    width: 150px;
    background-color: #FFF;
    border: none;
    height: 56px;
    cursor: pointer;
    line-height: normal;

}

.modal__form input[type="text"],
.modal__form input[type="email"],
.modal__form input[type="tel"] {
    border-radius: 0px;
    padding: 16px 19px;
    border: none;
    color: $c-grey-dark;
    box-sizing: border-box;
    width: 100%;
}

.modal__textarea {
    border-radius: 0px;
    padding: 26px 29px;
    height: 300px;
    box-sizing: border-box;
    width: 100%;
}

::-webkit-input-placeholder {

   color: rgba($c-grey-dark, 0.80);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($c-grey-dark, 0.80);
}

:-ms-input-placeholder {
    /* IE 10+ */
   color: rgba($c-grey-dark, 0.80);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($c-grey-dark, 0.80);
}

.map {
    min-height: 80vh;
    @media screen and (max-width: 768px) {
         min-height: 80vh;
    }

    img {
      display: block;
    }

}
.map > div {
  width: 100% !important;
  height: 100% !important;
}

$modalBP: 650px;

.modalX {
  display: flex; 
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modalX-content {
  background-color: $c-white;
  margin: 15% auto;
  padding: 0px;
  border: 0px solid #888;
  width: 100%;
  max-width: 540px;
  text-align: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  
  @media screen and (min-width: $modalBP){
    flex-wrap: nowrap;
  }
}

.modalX-txt {
  padding: 15px;
  text-transform: uppercase;
  color: rgba(60,60,60,1);
  width: 100%;
  @media screen and (min-width: $modalBP){
    width: 50%;
    padding: 90px 60px 60px 60px ;
  }
}

.modalX-txt h2 {
    font-family: $panton_semiboldregular;
  text-align: left;
  text-transform: uppercase;
  color: rgba(60,60,60,1);
  margin-bottom: 20px;
  margin-top: 0px;
  font-size: 26px;
    line-height: 31px;
  @media screen and (min-width: $modalBP){
    margin-bottom: 0px;
    font-size: 33px;
    line-height: 36px;
    margin-bottom: 10px;
  }
}

.modalX-txt p {
    font-family: $panton_semiboldregular;
  text-align: left;
  margin-bottom: 20px;
}


.modalX-img {
    position: relative;
    width: 100%;
    @media screen and (min-width: $modalBP){
      width: 50%;
    }
}

.modalX-img  img {
    width: 100%;
  height: auto;
  @media screen and (min-width: $modalBP){
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right bottom;
  }

}

.charity_big {
  display: none;
  @media screen and (min-width: $modalBP){
    display: block;
  }
}
.charity_mobile {
  display: block;
  @media screen and (min-width: $modalBP){
    display: none;
  }
}


.modalX-content .close {
  color: #FFFFFF;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
  z-index: 10;
}

.modalX-content .close:hover,
.modalX-content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modalX-content .button {
    font-family: $panton_semiboldregular;
  padding: 14px 20px 12px 20px;
  font-size: 16px;
  text-decoration: none;
  color: $c-gold;
  background-color: #e8cb99;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  display: inline-block;
}

.modalX-content .button:hover {
  background-color: $c-brown-light;
}

.modal-xmass-img {
  width: 100%;
  display: block;
  max-width: 650px;
}

.modal-xmass-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(0px) scale(1);
  bottom: 7%;
  display: block;
  width: auto;
  padding: 10px 13px 8px 13px;
  background-color: white;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: #000;
  box-shadow: 2px 6px 19px 0px rgba(0, 0, 0, 0);
  transition: all 0.5s ease;
  @include trans();
  @media screen and (max-width: 650px){
    font-size: 2.5vw;
    padding: 2vw 2.4vw;
  }
  &:hover {
    @include trans();
    transform: translateX(-50%) translateY(-2px) scale(1.05);
    box-shadow: 2px 6px 19px 0px rgba(0,0,0,0.4);
  }
}

/* MODAL STOP */

.top_bar {
    background-color: $c-brown-bar;
    padding: 10px 40px;
    position: relative;
    z-index: 1000;
    text-align: center;
    color: $c-white; 
}

.top_bar a {
  color: $c-white;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 1.125rem;
}

.top_bar_btn {
  background: transparent;
  background-color: rgba(0,0,0,0);
  border: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}