/* OTHERS */
.others__top {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  justify-content: center;
  color: $c-white;;
  padding-top: 200px;
  padding-bottom: 60px;
  @media screen and (max-width: 650px) {
    padding-top: 300px;
    padding-bottom: 40px;
  }
}
.others__restaurant {
  background-image: url(./img/back_restaurants23.jpg);
}
.others__wellness {
  background-image: url(./img/back23_wellness.jpg);
}
.others__events {
  background-image: url(./img/back_events.jpg);
}
.others__top h1 {
  margin-top: 0;
  margin-bottom: 95px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 45px;
    
  }
  
}

.others__txt {
  margin-top: -60px;
  margin-bottom: 55px;
  @media screen and (max-width: 650px) {
    margin-bottom: 20px;
    
  }
}

.others__txt p {
  margin: 0;
  padding: 60px 55px;
  background-color: $c-white;
  text-align: center;
  color: $c-grey;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  margin-left: 60px;
  margin-right: 60px;
  @media screen and (max-width: 990px) {
    padding: 40px 35px;
    font-size: 1.3625rem;
    line-height: 1.575rem;
    margin-left: 40px;
    margin-right: 40px;
  }
  @media screen and (max-width: 650px) {
    padding: 20px 25px;
    font-size: 1.1rem;
    line-height: 1.25rem;
    margin-left: 10px;
    margin-right: 10px;
    
  }
}

.other__slider {
  position: relative;
}
.other__slider__img {
  width: 100%;
  object-position: center center;
  object-fit: cover;  
}
.other__cont {
  margin-bottom: 70px;
  @media screen and (max-width: 650px) {
    margin-bottom: 40px;
  }
}
.other__cont .container__noPadd {
  overflow: hidden;
}

.others .tns-outer {
  position: relative;
}
.others .tns-controls {
  position: absolute;
  
  right: 2%;
  top: 50%;
  width: 96%;
  transform: translateY(-50%);
  z-index: 20;
  height: 80px;
}

.others .tns-controls button {
  position: absolute;
  background-color: transparent;

  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./img/arrowleftCarousel.svg');
  background-size: 46px 80px;
  width: 46px;
  height: 80px;
  margin: 0;  
  top: 0px;
  display: block;
  &:first-child {
    left: 0px;
  }
  &:last-child {
    right: 0px;
    transform: rotate(180DEG);
  }
}

.others .tns-nav {
  position: absolute;
  
  right: 0px;
  bottom: 20px;
  width: 100%;
  z-index: 20;
  height: 25px;
 
  text-align: center;
  @media only screen and (max-width: 990px) {
    width: calc(100%);
    left: 0px;
  } 
}
.others .other__right {
  .tns-nav {
    right: inherit;
    left: 0px;
  } 
}
.others .tns-nav button {
  @include trans();
  background-color: rgba($c-white,0);
  width: 20px;
  height: 20px;
  border: 2px solid rgba($c-white,1);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4); 
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4);
  display: inline-block;
  border-radius: 50%;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
}
.others .tns-nav-active {
  @include trans();
  background-color: rgba($c-white,1) !important;
}

.reservario {
  margin-top: 20px;
}

.others_mainInfo {
  margin-bottom: 38px;
  @media screen and (max-width: 650px) {
    margin-bottom: 20px;
    
  }
}
.others_mainInfo h2 {
  margin-top: 0;
  margin-bottom: 25px;
  color: $c-gold;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 25px;
    
  }
}
.others_mainInfo p {
  color: $c-grey-dark;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  margin-bottom: 35px;
  text-align: center;
  @media screen and (max-width: 990px) {
    font-size: 1.3625rem;
    line-height: 1.575rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.1rem;
    line-height: 1.25rem;
    margin-bottom: 25px;    
  }
}
.others_mainInfo_btns {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
}

.others_mainInfo_btn {
  background-color: $c-brown-light;
  display: inline-block;
  width: 200px;
  text-align: center;
  padding: 20px 15px;
  font-size: 1.25rem;
  line-height: normal;
  color: $c-grey-dark;
  @include trans();
  border: none;
  cursor: pointer;
  &:hover {
    @include trans();
    color: $c-brown-light;
    background-color: $c-grey-dark;
  }
}