
/* CONTACT */
body.contact {
  background-color: #FFF;
}
.contact__top {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./img/hp_top_contact.jpg);
  display: flex;
  align-items: stretch;
  justify-content: center;
  color: $c-white;;
  padding-top: 200px;
  padding-bottom: 60px;
  @media screen and (max-width: 650px) {
    padding-top: 300px;
    padding-bottom: 40px;
  }
}


.contact__top h1 {
  margin-top: 0;
  margin-bottom: 0px;
  color: $c-white;
  font-size: 4.375rem;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.69);
  @media screen and (max-width: 650px) {
    font-size: 3.4375rem;
    line-height: 3.75rem;
    margin-bottom: 0px;
    
  }
  
}
.contact_mainInfo {
  padding-top: 60px;
  padding-bottom: 20px;
  font-size: 1.5625rem;
  line-height: 1.9rem;
  @media screen and (max-width: 650px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.contact_mainInfo a {
  text-decoration: underline;
  color: $c-gold;
}