/* FORMS */
.er {
    background-color: red;
    color: #FFF;
    margin-bottom: 25px;
    padding: 20px;
    font-weight: bold;
}
.ch {
    background-color: rgb(9, 152, 30);
    color: #FFF;
    margin-bottom: 25px;
    padding: 20px;
    font-weight: bold;
}

.form__in {
    border-radius: none;
    background-color: #FFF;
    padding: 16px 19px;
    border: none;
    color: $c-grey-dark;
    width: 100%;
}

.form__ta {
    width: 100%;
    height: 300px;
    display: block;
}