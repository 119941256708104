.map {
  min-height: 80vh;
  
}
.foot__wrap {
  padding-top: 75px;
  background-color: $c-white;
  @media screen and (max-width: 990px ){
    padding-top: 55px;
    
  }
  @media screen and (max-width: 650px ){
    padding-top: 25px;
  }
}
.foot {
  background-image: url(./img/back_foot.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 75px;
  padding-bottom: 75px;
  color: $c-white;
  background-color: $c-white;
  margin-top: 75px;
  @media screen and (max-width: 990px ){
    padding-top: 55px;
    padding-bottom: 55px;
    margin-top: 55px;
  }
  @media screen and (max-width: 650px ){
    padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 25px;
  }
}

.foot__txt a {
  color: $c-white;
  @include trans();
  text-decoration: underline;
  &:hover {
    @include trans();
    color: $c-brown-light;
  }
}
.foot__icon {
  margin-bottom: 32px;
  display: inline-block;
  @media screen and (max-width: 650px ){
    margin-bottom: 32px;
    margin-right: 20px;
  }
}
.foot__icon path {
  @include trans();
  fill: #FFFFFF;
}
.foot__icon:hover path {
  @include trans();
  fill: $c-brown-light;
}

.foot__txt {
  display: flex;
  margin-bottom: 32px;
  gap: 16px;
  @media screen and (max-width: 650px ){
    display: block;
  }
}
.foot__adress {
  flex: 1;
  @media screen and (max-width: 650px ){
    display: block;
    flex: none;
    margin-bottom: 32px;
  }
}
.foot__owner {
  flex: 1;
  margin-right: 20px;
  @media screen and (max-width: 650px ){
    display: block;
    flex: none;
    margin-bottom: 32px;
    margin-right: 0px;
  }
}
.foot__social {
  width: 250px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 650px ){
    width: 100%;
    text-align: center;
    justify-content: flex-start;
  }
}
.foot__social_img {
  display: block;
  width: auto;
  max-width: 100%;
 height: 200px;
  margin: 0 0 0 auto;
  @media screen and (max-width: 650px ){
    margin: 0 auto;
  }
}

.foot__social a {
  display: block;
  width: auto;
}

.foot__iconSocial {
  order: 2;
  @media screen and (max-width: 650px ){
    order: 1;
  }
}

.foot__amazing {
  order: 1;
  @media screen and (max-width: 650px ){
    order: 2;
  }
}

.foot__owner__logos {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
}

.foot__owner__logos img {
  display: block;
}