/* VARIABLES */

/* FONTS */
$panton_narrowregular: "panton_narrowregular", helvetica, arial, sans-serif;
$pantonregular: "pantonregular", helvetica, arial, sans-serif;
$panton_semiboldregular: "panton_semiboldregular", helvetica, arial, sans-serif;


/* COLORS */
$c-white: rgba(255,255,255,1); //#FFFFFF
$c-pink: rgba(253,104,220,1); //#fd68dc
$c-yellow: rgba(206,222,0,1); //#cede00
$c-black: rgba(0,0,0,1); //#000000
$c-gold: rgba(123,102,40); //#7B6628
$c-brown-light: rgba(232,203,153); //#E8CB99
$c-brown-bar: rgba(179,137,38); //#E8CB99

$c-grey: rgba(60,60,60,1); //#53575f
$c-grey-light: rgba(90,90,90,1); //#5a5a5a
$c-grey-dark: rgba(60,60,60,1); //#3c3c3c
$c-grey-back: rgba(237,236,235,1); //#EDECEB

$c-blue: rgba(194,229,222,1); //#C2E5DE

/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1280px;



/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
